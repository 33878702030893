<template>
  <CContainer fluid>
    <CRow>
      <CCol sm="8">
        <CCard>
          <CCardHeader>
            <CRow class="justify-content-between px-3">
              <b>User Internal</b>
              <div class="d-flex">
                <CButton
                  @click="changeStatusModal = true"
                  size="sm"
                  color="warning"
                  class="mr-2"
                  >Change Status</CButton
                >
                <CButton
                  size="sm"
                  color="dark"
                  class="mr-2"
                  @click="onFetchNoteInternal"
                  >Internal Notes</CButton
                >
              </div>
            </CRow>
          </CCardHeader>
          <CCardBody>
            <CRow>
              <dd class="col-sm-12">
                <CImg
                  v-bind:src="user.photo_url || 'img/no-avatar.png'"
                  width="100px"
                  height="100px"
                  class="mb-2"
                  shape="rounded"
                />
              </dd>

              <dt class="col-sm-2">ID</dt>
              <dd class="col-sm-10">{{ user.id }}</dd>
              <dt class="col-sm-2">Name</dt>
              <dd class="col-sm-10">{{ user.name }}</dd>
              <dt class="col-sm-2">Email</dt>
              <dd class="col-sm-10">{{ user.email }}</dd>
              <dt class="col-sm-2">Role</dt>
              <dd class="col-sm-10">{{ user.role }}</dd>
              <dt class="col-sm-2">Status</dt>
              <dd class="col-sm-10">
                <CBadge :color="getUserBadge(user.status)">
                  {{ user.status }}
                </CBadge>
              </dd>
              <dt class="col-sm-2">LastLogin</dt>
              <dd class="col-sm-10">{{ moment(user.last_login).fromNow() }}</dd>

              <dt class="col-sm-2">CreatedAt</dt>
              <dd class="col-sm-10">
                {{ user.updated_at | moment(datetimeFormat) }}
              </dd>
            </CRow>
          </CCardBody>
        </CCard>
      </CCol>
    </CRow>
    <CRow>
      <CCol sm="8">
        <CCard>
          <CCardHeader>
            <CRow class="justify-content-between px-3">
              <b>Permissions</b>
              <div class="d-flex">
                <CButton
                  @click="editUserInternalPermistionModal = true"
                  size="sm"
                  color="primary"
                  class="mr-2"
                  >Edit</CButton
                >
              </div>
            </CRow>
          </CCardHeader>
          <CCardBody>
            <CRow>
              <dd class="col-sm-10">
                <template v-for="permission in user.permissions">
                  <CBadge :color="`secondary`" class="mr-1" :key="permission">
                    {{ permission }}
                  </CBadge>
                </template>
              </dd>
            </CRow>
          </CCardBody>
        </CCard>
      </CCol>
    </CRow>
    <CModal title="Change status" :show.sync="changeStatusModal" size="lg">
      <CCol sm="4">
        <CSelect
          label="Status"
          :value.sync="statusSelected"
          :options="options"
        />
      </CCol>
      <template #footer>
        <CButton @click="changeStatusModal = false" color="secondary"
          >Cancel</CButton
        >
        <CButton @click="onChangeStatus" color="primary">Save</CButton>
      </template>
    </CModal>

    <ViewNoteInternalComponent
      title="Notes"
      :isShow="showNoteInternalList"
      :noteData="noteData"
      @close="showNoteInternalList = false"
    />
    <EditUserInternalPermissionModal
      :showModal="editUserInternalPermistionModal"
      :permissions="user.permissions || []"
      @close="editUserInternalPermistionModal = false"
      @save="onEditUserInternalPermission"
    />
  </CContainer>
</template>

<script>
import {
  ACTION_FETCH_USER_INTERNAL,
  ACTION_FETCH_PROFILE,
} from '@/store/actions.type';
import { UserBadge } from '@/utils/utils';
import UserInternalProxy from '@/proxies/userinternal.proxy';
import AdminProxy from '@/proxies/admin.proxy';
import ViewNoteInternalComponent from '@/components/ViewNoteInternalComponent';
import EditUserInternalPermissionModal from '@/components/modals/EditUserInternalPermissionModal';
import { Common } from '@/constants';
import { mapGetters } from 'vuex';

export default {
  name: 'UserInternal',
  beforeRouteEnter(to, from, next) {
    next((vm) => {
      vm.usersOpened = from.fullPath.includes('user-internals');
    });
  },
  components: {
    ViewNoteInternalComponent,
    EditUserInternalPermissionModal,
  },
  data() {
    return {
      datetimeFormat: Common.datetimeFormat,
      id: undefined,
      usersOpened: null,
      changeStatusModal: false,
      statusSelected: 'active',
      options: [
        { value: 'active', label: 'Active' },
        { value: 'inactive', label: 'Inactive' },
        { value: 'banned', label: 'Banned' },
      ],
      showNoteInternalList: false,
      noteData: {
        notes: [],
        id: undefined,
        type: 'user-internal',
      },
      editUserInternalPermistionModal: false,
    };
  },
  mounted() {
    this.id = this.$route.params.id;
    this.noteData.id = this.id;

    this.$store.dispatch(ACTION_FETCH_USER_INTERNAL, this.id);
  },
  computed: {
    ...mapGetters({
      user: 'userinternal',
      profile: 'profile',
    }),
  },
  methods: {
    goBack() {
      this.usersOpened
        ? this.$router.go(-1)
        : this.$router.push({ path: '/user-internals' });
    },
    getUserBadge(status) {
      return UserBadge(status);
    },
    onChangeStatus() {
      const data = { status: this.statusSelected };
      UserInternalProxy.changeStatus(this.id, data).then(() => {
        this.user.status = this.statusSelected;
        this.changeStatusModal = false;
      });
    },
    onFetchNoteInternal() {
      let query = { type: this.noteData.type, id: parseInt(this.id) };
      AdminProxy.fetchNoteInternal(query).then(({ data }) => {
        this.showNoteInternalList = true;
        this.noteData.notes = data;
      });
    },
    onEditUserInternalPermission(val) {
      AdminProxy.updateUserInternal(this.id, { permissions: val }).then(() => {
        this.editUserInternalPermistionModal = false;
        this.$toast.success(`Edit permissions successfully`, {
          duration: 3000,
        });
        this.$store.dispatch(ACTION_FETCH_USER_INTERNAL, this.id);
        if (this.id == this.profile?.id) {
          this.$store.dispatch(ACTION_FETCH_PROFILE, this.id);
        }
      });
    },
  },
};
</script>
