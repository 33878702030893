<template>
  <CContainer fluid>
    <CRow>
      <CCol sm="8">
        <CCard>
          <CCardHeader>
            <b>Profile</b>
          </CCardHeader>
          <CCardBody>
            <CRow>
              <dd class="col-sm-12">
                <CImg
                  v-bind:src="profile.photo_url || 'img/no-avatar.png'"
                  width="100px"
                  height="100px"
                  class="mb-2"
                  shape="rounded"
                />
              </dd>

              <dt class="col-sm-2">Name</dt>
              <dd class="col-sm-10">{{ profile.name }}</dd>
              <dt class="col-sm-2">Email</dt>
              <dd class="col-sm-10">{{ profile.email }}</dd>
              <dt class="col-sm-2">Phone</dt>
              <dd class="col-sm-10">{{ profile.phone_number }}</dd>
              <dt class="col-sm-2">Role</dt>
              <dd class="col-sm-10">{{ profile.role }}</dd>
              <dt class="col-sm-2">Status</dt>
              <dd class="col-sm-10">
                <CBadge :color="getUserBadge(profile.status)">
                  {{ profile.status }}
                </CBadge>
              </dd>
              <dt class="col-sm-2">Permissions</dt>
              <dd class="col-sm-10">
                <template v-for="permission in profile?.permissions">
                  <CBadge :color="`secondary`" class="mr-1" :key="permission">
                    {{ permission }}
                  </CBadge>
                </template>
              </dd>
              <dt class="col-sm-2">CreatedAt</dt>
              <dd class="col-sm-10">{{ profile.updated_at }}</dd>
            </CRow>
          </CCardBody>
        </CCard>
      </CCol>
    </CRow>
  </CContainer>
</template>

<script>
import { ACTION_FETCH_PROFILE } from '@/store/actions.type';
import { mapGetters } from 'vuex';
import { UserBadge } from '@/utils/utils';

export default {
  name: 'Profile',
  data() {
    return {};
  },
  mounted() {
    this.$store.dispatch(ACTION_FETCH_PROFILE);
  },
  computed: {
    ...mapGetters(['profile']),
  },
  methods: {
    getUserBadge(status) {
      return UserBadge(status);
    },
  },
};
</script>
